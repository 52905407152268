<template>
    <div>
        <b-card no-body>
            <b-card-header class="pb-50">
                 <h5>
        Create Chart
      </h5>
            </b-card-header>
            <b-card-body>
        <b-row>
             <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
          placeholder="Select a Chart Type"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
                <b-form-group>
      <b-input-group>
        <b-form-input
        v-model="titlegrap"
          placeholder="Insert Chart Title"
        />
      </b-input-group>
    </b-form-group>
          </b-col>
            <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selectedx"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="optionx"
          placeholder="Select a X axis"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
      <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selectedy"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="optiony"
          placeholder="Select a Y axis"
          :searchable="false"
        />
      </b-form-group>
    </b-col>

        </b-row>
        </b-card-body>
        <footer>
           <div class="text-right">
         <b-button
         variant="flat-success"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      @click="createGraph"
    >
    <feather-icon
    icon="CheckIcon"/>
      {{titlebtn}}
    </b-button>
    </div>
        </footer>
           </b-card>
             <b-row v-if="cambio!=0">
          <b-col>
            <div class="text-right mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="showModal=!showModal"
          >
            <feather-icon icon="SaveIcon"/>
              Save
          </b-button>
        </div>
          </b-col>
        </b-row>
        <b-row>
             <!--b-col cols="12"  v-if="showSpinner">
          <div class="text-center text-success">
        <b-spinner  style="width: 3rem; height: 3rem;"/>
        </div>
      </b-col-->
             <b-col cols="12" v-if="cambio==1">
      <apex-multipe-bar-chart :chartTitle="titlegrap" :cseries="series" 
      :labels="labels" :key="cambio" :color="['#00E396']" :numerograph="0"/>
    </b-col>
       <b-col  cols="12" v-if="cambio==2">
           <apex-line-area-chart :chartTitle="titlegrap"
       :cseries="series" :labels="labels" :key="cambio" :estado="true" :numerograph="0"/>
    </b-col>
    <b-col cols="12" v-if="cambio==3">
        <apex-line-area :chartTitle="titlegrap" :cseries="series"
        :labels="labels" :key="cambio" :numerograph="0"/>
    </b-col>
        </b-row>
         <b-modal
        v-model="showModal"
      title="Save Chart"
      ok-only
      centered
      ok-title="Save"
      @ok="saveChart"
    >
      <b-card-text>
      Do you want to save the Chart?
      </b-card-text>
    </b-modal>
    </div>
</template>
<script>
import {BCol,BRow,BFormGroup,BButton,BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,
BModal,BCardText,BCardFooter} from "bootstrap-vue"
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive"
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexLineArea from "@/components/charts/apex-chart/ApexLine&Area.vue"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
export default {
    props:['items','fields','sql'],
    directives:{Ripple},
    components:{BCol,BRow,BFormGroup,vSelect,BButton,ApexLineAreaChart,ApexLineArea,ApexMultipeBarChart,
    BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,BModal,BCardText,BCardFooter},
    data(){
        return{
             selected:null,
             selectedx:null,
             selectedy:null,
      option: [{ title: 'Bar Chart',value:1 }, { title: 'Area Chart',value:2 }, { title: 'Line Chart',value:3}],
      optionx:this.fields,
      optiony:this.fields,
      series:[],
      labels:[],
      cambio:0,
      titlegrap:null,
       showSpinner:true,
       titlebtn:'Create',
        showModal: false,
        id:''
        }
    },
    methods:{
        createGraph:function(){
          if (this.selected!=null && this.titlegrap!=null && this.selectedx!=null && this.selectedy!=null ) {
            var arr=[]
            this.cambio=0
            this.labels=[]
            this.series=[]
            this.titlebtn='Update' 
             for (let x = 0; x < this.items.length; x++) {
                if (this.selectedx!=null && this.selectedy!=null) {
                arr.push(this.items[x][this.selectedy])
                this.labels.push(this.items[x][this.selectedx])   
                }
            }            
          if (this.selected.value==1) { 
                this.series.push({name:this.selectedy,data:arr})
                this.cambio=1   
            }
            if (this.selected.value==2) {
                   this.series.push({name:this.selectedy,data:arr})
                this.cambio=2
            }
            if (this.selected.value==3) {
                   this.series.push({name:this.selectedy,type:'line',data:arr})
                this.cambio=3
            }
                  this.showSpinner=false
                  }
            else {
              this.$swal({
        title: 'Error',
        text: 'Enter all requested information!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })

                  }
        },
        saveChart:function(){
           const vm=this;
            const data={
        sql:this.sql,
        chart:this.selected,
        title:this.titlegrap,
        xaxis:this.selectedx,
        yaxis:this.selectedy,
        id:this.id
    }
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post("/api/v1/sel/svchg",data,config).then(function (response) {
       vm.$swal({
        title: 'Excelent!',
        text: 'Chart saved successfully!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

      vm.$root.$emit("newchart",true);
    })
    .catch((err) => {
      console.log(err);
    });
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>