<template>
  <div>
    <b-row>
      <b-col>
         <div class="text-right mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="refreshData"
          >
            <feather-icon icon="PlayCircleIcon" />
          </b-button>
        </div>
            <div v-if="synthax">Synthax Error</div>
      </b-col>
    </b-row>    
    <b-row class="match-height">
      <b-col cols="2" class="mr-0 pr-0 pb-0 mb-0">
        <b-card-actions no-body class="pb-0 mb-0" action-collapse  title="Dust">
        
      
    <b-list-group flush v-for="(item,index) in originalFields" :key="item">
    <b-list-group-item  @click="buildQuery(index)">{{item}}</b-list-group-item>
  </b-list-group>
      
        </b-card-actions>
      </b-col>
      <b-col class="ml-0 pl-0">
         <AceEditor
      v-model="content"
      lang="mysql"
      @init="initEditor"
      theme="tomorrow_night"
    ></AceEditor>
      </b-col>
    </b-row>
    
    <b-tabs class="mt-1" :key="updateTabs">
      <b-tab title="Query Result" active>
            <b-card no-body class="border mt-2">
      <b-card-header class="p-1">
          <div>
            <b-card-title>SQL Result</b-card-title>
          </div>
          <div>
              <b-form-group
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group >
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Filter by Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
          </div>
      </b-card-header>
      <div>
          <b-alert variant="warning" show>
      <div class="alert-body">
        <span><strong>Displaying!</strong> First 50 results.</span>
      </div>
    </b-alert>
      </div>
    <b-table
      :busy="isBusy"
      :items="items"
      :fields="fields"
         striped
      responsive
       :per-page="perPage"
      :current-page="currentPage"
          :filter="filter"
        :filter-included-fields="filterOn"
            @filtered="onFiltered"
        class="mb-0"
    >
         <template #table-busy>
        <div class="text-center text-success my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
        <b-col cols="12">
        <b-row class="match-height">
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="md"
              class="my-0 mt-1 mb-1"
            />
          </b-col>
        </b-row>
      </b-col>
      </b-card>
      </b-tab>
      <b-tab title="New Chart">
        <create-graphic :items="items" :fields="fields" :sql="content" v-if="cambio==1" />
      </b-tab>
      <b-tab :title="x.title" v-for="(x,i) in dataChart" :key="i">
          <load-graphics :alldata="x" :fields="fields" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import AceEditor from 'vue2-ace-editor';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {BButton, BRow, BCol, BTable,BListGroup,BSpinner, BListGroupItem,BCard,BCardHeader,BCardTitle,
BFormInput,BFormGroup,BInputGroupAppend,BInputGroup,BPagination,BAlert,BTabs, BTab } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import 'brace/ext/language_tools';
import 'brace/mode/mysql';
import 'brace/theme/tomorrow_night';
import 'brace/snippets/mysql';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import BCardActions from '@/components/cards/BCardActionMod.vue'
import CreateGraphic from "@/views/CreateGraphic.vue"
import LoadGraphics from "@/views/LoadGraphic.vue"
export default {
  directives: {Ripple},
  components: {
    AppCollapse,
    AppCollapseItem,
    AceEditor,
    BButton,
    BRow,
    BCol,
    BTable,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardHeader,
    BSpinner,
    BCardTitle,BAlert,VuePerfectScrollbar,
    BFormInput,BFormGroup,BInputGroupAppend,BInputGroup,BPagination,
    BCardActions,BTabs, BTab,CreateGraphic,LoadGraphics
    
  },
  data: function () {
    return {
      isBusy: true,
      updateTabs:0,
      synthax: false,
      fields: [],
      items: [],
      originalFields:null,
      content: 'select * from dust  where owner="DnqbDv7GaXm2Stgyp5ZT6DihfqooZr3U9egxz4PEZf8j"',
      options: {
        autoScrollEditorIntoView: true,
        enableBasicAutocompletion: true,
        enableSnippets: true,
        enableLiveAutocompletion: true,
        fontSize: '15pt',
      },
      perfectScrollbarSettings : {
      maxScrollbarLength: 10,
    },
          filter: null,
        filterOn: [],
           perPage: 50,
        totalRows: 1,
        currentPage: 1,
        cambio:0,
        dataChart:[]
    };
  },
  mounted:function(){
    this.refreshData();
    this.loadCharts()
    this.$root.$on("newchart", (data) => {
       
      if (data) {
        this.updateTabs=1;
        this.loadCharts();
        this.updateTabs=2;
      }
    });
  },
  methods: {
    initEditor: function (editor) {
      editor.getSession().setMode('ace/mode/mysql');
      editor.setShowPrintMargin(false);
      editor.setOptions(this.options);

      editor.setShowPrintMargin(false);
    },
    refreshData: function () {
      this.isBusy=true
      this.items = [];
      this.fields = [];
      const vm = this;
      const axios = require('axios');
      const formData = {
        qOne: this.content,
      };
     const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      this.synthax = false;
      axios
        .post('/api/v1/sel/tksqlas', formData, config)
        .then(function (response) {
           vm.totalRows=response.data.info.length-1
          const xd = response.data;

          if (xd.mr == 2) {
            vm.items = xd.info;
            vm.fields = xd.cols;
            if(vm.originalFields==null)
            {
              vm.originalFields=xd.cols;
            }
            vm.isBusy = false;
            vm.cambio=1
          } else {
            vm.synthax = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    buildQuery:function(i){
      var query="select "
      if (i==-1){
        this.fields.forEach((item,ind) => query += item+(this.fields.length-1!=ind?', ':" from dust"));
      this.content=query
      }
      else if(this.content.indexOf("*")>=1)
      {
        query+=this.fields[i]+" from dust"
        this.content=query;

      }
      else if(this.content.indexOf("* from")==-1){
       var index=this.content.indexOf("from")
       var str2 = this.content.substring(0,index)+", "+this.fields[i]+" "+this.content.substring(index);   
       this.content=str2 

      }
    },
     onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
     loadCharts:function(){
        const vm=this
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("/api/v1/sel/bgchg",config).then(function (response) {
    if (response.data.length>0) {
      vm.dataChart=response.data
    }

    })
    .catch((err) => {
      console.log(err);
    });
        }
  },
};
</script>

<style>
</style>
