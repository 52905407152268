<template>
    <div>
        <b-card no-body>
            <b-card-header class="pb-50">
                 <h5>
        Create Chart
      </h5>
            </b-card-header>
            <b-card-body>
        <b-row>
             <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
          placeholder="Select a Chart Type"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
    <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
                <b-form-group>
      <b-input-group>
        <b-form-input
        v-model="titlegrap"
          placeholder="Insert Chart Title"
        />
      </b-input-group>
    </b-form-group>
          </b-col>
            <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selectedx"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="optionx"
          placeholder="Select a X axis"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
      <b-col cols="12" lg="3" md="6" sm="6" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selectedy"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="optiony"
          placeholder="Select a Y axis"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
        </b-row>
        </b-card-body>
        <footer>
           <div class="text-right">
         <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
         variant="flat-success"
      @click="createGraph"
    >
    <feather-icon
    icon="RefreshCwIcon"/>
      Update
    </b-button>
    </div>
        </footer> 
           </b-card>

             <b-row v-if="cambio!=0">
          <b-col>
            <div class="text-right mb-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="showModal=!showModal"
          >
            <feather-icon icon="RefreshCwIcon"/>
              Update
          </b-button>
        </div>
          </b-col>
        </b-row>
        <b-row>
              <b-col cols="12">
                  <b-card no-body>
                     <b-card-header class="pb-50">
                      <h5>SQL</h5>
                    </b-card-header>
                    <b-card-body>

                      <b-form-textarea
                      v-model="csql"
                        rows="1"
                        disabled
                      />
                      </b-card-body>
                      <footer>
                        <div class="text-right p-1">
                                 <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              variant="primary"
                              @click="doCopy"
                            >
                              Copy!
                            </b-button>
                        </div>
                        </footer> 
                  </b-card>
                  </b-col>
          

             <b-col cols="12" v-if="cambio==1">
      <apex-multipe-bar-chart :chartTitle="titlegrap" :cseries="series" 
      :labels="labels" :key="cambio" :color="['#00E396']" :numerograph="0"/>
    </b-col>
       <b-col  cols="12" v-if="cambio==2">
           <apex-line-area-chart :chartTitle="titlegrap"
       :cseries="series" :labels="labels" :key="cambio" :estado="true" :numerograph="0"/>
    </b-col>
    <b-col cols="12" v-if="cambio==3">
        <apex-line-area :chartTitle="titlegrap" :cseries="series"
        :labels="labels" :key="cambio" :numerograph="0"/>
    </b-col>
        </b-row>
         <b-modal
        v-model="showModal"
      title="Save Chart"
      ok-only
      centered
      ok-title="Save"
      @ok="saveChart"
    >
      <b-card-text>
      Do you want to save the Chart?
      </b-card-text>
    </b-modal>
    </div>
</template>
<script>
import {BCol,BRow,BFormGroup,BButton,BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,
BModal,BCardText,BFormTextarea} from "bootstrap-vue"
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive"
import ApexLineAreaChart from "@/components/charts/apex-chart/ApexLineAreaChart.vue"
import ApexLineArea from "@/components/charts/apex-chart/ApexLine&Area.vue"
import ApexMultipeBarChart from "@/components/charts/apex-chart/ApexMultipleBarChart.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
   directives: {
    Ripple,
  },
    props:['items','fields','sql','alldata'],
    directives:{Ripple},
    components:{BCol,BRow,BFormGroup,vSelect,BButton,ApexLineAreaChart,ApexLineArea,ApexMultipeBarChart,
    BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,BModal,BCardText,BFormTextarea},
    data(){
        return{
             selected:this.alldata.chartType,
             selectedx:this.alldata.xaxis,
             selectedy:this.alldata.yaxis,
      option: [{ title: 'Bar Chart',value:1 }, { title: 'Area Chart',value:2 }, { title: 'Line Chart',value:3}],
      optionx:this.fields,
      optiony:this.fields,
      series:[],
      labels:[],
      cambio:0,
      titlegrap:this.alldata.title,
       showSpinner:true,
        showModal: false,
        id:this.alldata.id,
        ald:this.alldata,
        csql:this.alldata.sql
        }
    },
    mounted(){
        this.loadChart(this.ald)
    },
    methods:{
        createGraph:function(){
            this.cambio=0
            this.labels=[]
            this.series=[]
            this.loadChart(this.ald)
       
                  this.showSpinner=false
        },
        saveChart:function(){
           const vm=this;
            const data={
        sql:this.alldata.sql,
        chart:this.selected,
        title:this.titlegrap,
        xaxis:this.selectedx,
        yaxis:this.selectedy,
        id:this.id
    }
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post("/api/v1/sel/svchg",data,config).then(function (response) {
       vm.$swal({
        title: 'Excelent!',
        text: 'Chart saved successfully!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      vm.$root.$emit("newchart",true);
    })
    .catch((err) => {
      console.log(err);
    });
        },
           loadChart:function(v){
               var arr=[]
               var ejey=""
                this.titlebtn='Update'
          for (let x = 0; x < v.info.length; x++) {

            ejey=v.info[x][this.selectedy]
                arr.push(v.info[x][this.selectedy])
                this.labels.push(v.info[x][this.selectedx])   
            }
    if (this.selected.value==1) {
      this.cambio=1
       this.series.push({name:ejey,data:arr})
    }
     if (this.selected.value==2) {
      this.cambio=2
       this.series.push({name:ejey,data:arr})
    }
     if (this.selected.value==3) {
      this.cambio=3
       this.series.push({name:ejey,data:arr})
    }
      this.showSpinner=false
        },
        doCopy() {
      this.$copyText(this.csql).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Text copied',
            icon: 'BellIcon',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      })
    },
        
       
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>